import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExternalLinkNewTab from "../components/external_link_new_tab"
import InternalLink from "../components/internal_link"


const Container = styled.div`
ul {
	position: relative;
	list-style: none;
	margin-left: 0;
	padding-left: 1.2em;
}
ul li:before {
	content: url('/images/award.png');
	position: absolute;
	left: 0;
}
ul li {
  padding-left: 1.0em;
}
`

const VitaPage = () => (
  <Layout>
    <SEO title="Vita" />
   
      <h1>VITA</h1>
        <div>
          Spätestens seit Jan Krebs 1990 in Nürnberg geboren wurde, war er am Leben. Momentan ist er das immer noch. Falls sich das ändert, sollte diese Vita aktualisiert werden.
          <br/><br/>Während und nach der Schulzeit war Jan Krebs an der Realisierung diverser Kurzfilme beteiligt und wurde unter anderem mit dem bayerischen Jugendfilmpreis für beste Regie ausgezeichnet. <br/>
          Nach dem Abitur absolvierte er Praktika bei lokalen Radiosendern, dem WDR, sowie dem Onlinemagazin hiphop.de. Danach studierte er irgendwas mit Medien in Erlangen. Während des Studiums drehte 
          er die Comedy-Webserien „In Tobis Küche“ für Brainpool, „Wulla Wussa“ für Festival Guide sowie den satirischen Vlog „Jans Fashion Blog“ für ein paar Daumen hoch auf YouTube.
          <br/>Im Jahr 2020 schloss er ein Drehbuch-Studium an der Filmakademie Baden-Württemberg ab.
          <br/><br/>Neben der Tätigkeit als Autor für Drehbücher, ist er auch aktiv als Gag-Autor für Show-Formate. <br>
          </br><br/>
          <br/><br/>
        </div>
         <h2>[QUALIFIKATIONEN]</h2>
        <Container>
          <ul>
            <li>Bayerischer Jugendfilmpreis - Beste Regie mit „Anarchie Revolution“ (<ExternalLinkNewTab destination="https://www.bkjff.de/bayern/2012-2/#bkjff-2012-Preisträger"/>)</li>
            <li>Master of Arts Theater- und Medienwissenschaft Friedrich-Alexander-Universität Erlangen-Nürnberg (<ExternalLinkNewTab destination="https://www.theater-medien.phil.fau.de/studium/masterstudiengaenge/"/>)</li>
            <li>Diplom der Filmakademie Ludwigsburg im Bereich Drehbuch Serie (<ExternalLinkNewTab destination="https://www.filmakademie.de/de/studium/studienangebote/serie/informationen/"/>)</li>
            <li>Stipendiat Baden-Württemberg-Stiftung für Filmproduktion (<ExternalLinkNewTab destination="https://www.bw-stipendium.de/studierende/bws-filmproduktion/"/>)</li>
            <li>Teilnehmer Hollywoodworkshop University of California, Los Angeles (<ExternalLinkNewTab destination="https://www.youtube.com/watch?v=hkSK2nalWaA&feature=emb_title"/>)</li>
            <li>Teilnehmer Masterclass Comedy-Serien EPI/Network Movie (<ExternalLinkNewTab destination="https://www.filmuniversitaet.de/artikel/detail/masterclass-comedy-serien-eine-veranstaltung-des-epi"/>)</li>
            <li>Träger des Frühschwimmer Seepferdchen Abzeichens 1996 (<ExternalLinkNewTab destination="https://www.dsv.de/fitness-gesundheit/schwimmabzeichen/seepferdchen/"/>)</li>
          </ul>
        </Container> 
        <br/>
    <InternalLink destination="/" label="ZURÜCK"/>
  </Layout>
)

export default VitaPage